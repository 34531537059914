import { useReports } from '@/api/hooks/useReports';
import React from 'react'
import Sidebar from '../Sidebar';
import { createReportDialog } from './report-wizard/CreateReport';
import Report from './Report';
import ReportsItems from './ReportsItem';

// const mockData = ["Парковки на завтра", "Брони на вчера", "Количество занятых мест"]
const mockData = [
    {
        name: "Список броней на выбранный день",
        key: "bookings-report"
    },
    {
        name: "Нагрузка на рабочее место",
        key: "place-report"
    },
    // {
    //     name: "Отчет по площадям",
    //     key: "square-report"
    // },
    // {
    //     name: "Список свободных мест на период",
    //     key: "empty"
    // }
]

const Reports = () => {

    const { reports } = useReports()

    return (
        <Sidebar>
            <Sidebar.Header title="reports" onAdd={createReportDialog} />
            <Sidebar.Content>
                {reports.map(item => <Report key={'report ' + item} report={item} />)}
                {mockData.map(item => <ReportsItems key={'report ' + item} item={item} />)}
            </Sidebar.Content>
        </Sidebar>
    )
}

export default Reports