import React, { ReactChild } from "react";
import styled from "styled-components";
import BaseLayout from "./BaseLayout";
import FloorBadge from "../FloorBadge";
import Controls from "./Controls";
import Preloader from "./../Preloader/Preloader";
import SidebarContainer from "@/containers/SidebarContainer";
import SeatBar from "../SeatBar";
import EmployeeBar from "../EmployeeBar";
import { BookingModal } from "@/components/shared/booking/form";
import MobileMenu from "./Sidebar/Menu/MobileMenu";
import LayerBar from "../LayerBar";
import MaintenanceModal from "../Maintenance/MaintenanceModal";
import Appbar from "./AppBar/AppBar";
import CreateReportModal from "./Sidebar/Reports/report-wizard/CreateReport";

type AppLayoutProps = {
  children: ReactChild | ReactChild[];
  title?: string;
  noIndex?: boolean;
  show?: boolean;
};

const AppLayout: React.FC<AppLayoutProps> = ({
  title,
  noIndex,
  children,
  show = false,
}) => {
  return (
    <BaseLayout title={title} noIndex={noIndex}>
      <Appbar />
      <Container>
        {children}
        <MobileMenu />
        <Controls />

        <FloorBadge />
        <SidebarContainer />
        <SeatBar />
        <EmployeeBar />
        <LayerBar />

        <BookingModal />
        <MaintenanceModal />
        <CreateReportModal />
      </Container>
      <Preloader show={show} />
    </BaseLayout>
  );
};

export default AppLayout;

const Container = styled.div`
  margin-top: 75px;
  /* padding: 1rem 2rem; */
  width: 100%;
  position: relative;
  max-height: 100%;
  max-width: 100vw;
  overflow: hidden;
`;
