import { Image, Layer, Rect } from "react-konva";
import useImage from "use-image";
import { ILayerView, LayerService } from "@/api/services/layer.service";
import { memo, useEffect, useMemo, useState } from "react";
import { useMapStore } from "@/stores/mapStore";
import { useGlobalStore } from "@/stores/globalStore";
import { useProjectStore } from "@/stores/projectStore";
import $config from "@/utils/Config";

const MapCoverLayer = ({ view }) => {
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const nodes = useProjectStore((state) => state.nodes);
  const [width, height] = useMapStore((state) => state.size);
  const setSize = useMapStore((state) => state.setSize);
  const setIsLoading = useMapStore((state) => state.setIsLoading);

  const [ownerId, setOwnderId] = useState(null);
  const imageURL = useMemo(() => getMapCoverImagePath(view), [ownerId]);
  const node = useMemo(() => nodes.find((n) => n.id == activeLayer), [nodes]);

  const [source, status] = useImage(imageURL, $config.settings.map.images);

  useEffect(() => {
    if (!view) {
      if (node && node.ownView) {
        setIsLoading(true);
      }
    } else {
      const {
        image: { owner_id },
      } = view;
      if (owner_id) {
        setOwnderId(owner_id);
      }
    }
  }, [view, activeLayer]);

  useEffect(() => {
    const predicate = source && status === "loaded";

    if (predicate) {
      setSize([source.width, source.height]);
      setIsLoading(false);
    } else {
      if (!node?.ownView) {
        setIsLoading(true);
      }
    }
  }, [source, status]);

  // if (status === 'loading') return null

  return (
    <Layer id="cover-layer" listening={false}>
       <Rect
          width={width}
          height={height}
          x={0}
          y={0}
          fill="#fff"
        />
      <Image image={source} />
    </Layer>
  );
};

export default memo(MapCoverLayer);

MapCoverLayer.whyDidYouRender = true;

const getMapCoverImagePath = (view: ILayerView | undefined) => {
  if (!view) return "";

  const urlPath = LayerService.getLayerImage({
    layerId: Number(view.image.owner_id),
    imageName: view.image.image_id,
    extension: view.image.extension,
  });

  return urlPath;
};

// const getCoverLayerId = (layerId: number, layers: TreeNodeData[]) => {
//     const node = layers.find(layer => layer.id === layerId)
//     if (!node || node.ownView) return layerId

//     return Number(node.parent)
// }
