import { Helmet } from 'react-helmet'
import React, { ReactChild } from 'react'
import styled from 'styled-components';
import DatePickerStyles from '@/ui/DatepickerStyles';
import { useIntl } from 'react-intl';

type BaseLayoutProps = {
    children: ReactChild | ReactChild[],
    title?: string,
    noIndex?: boolean
} 

const BaseLayout: React.FC<BaseLayoutProps> = ({ title, noIndex, children }) => {
    const intl = useIntl()

    const brandName = intl.formatMessage({ id: 'brand-name' }).toUpperCase()

    return (
        <>
            <Helmet>
                {title ? <title>{title} | {brandName}</title> : <title>{brandName}</title>}
                {noIndex && <meta name="robots" content="noindex" />}
            </Helmet>
            <RootWrapper>
                {children}
                <DatePickerStyles />
            </RootWrapper>
        </>
    )
}

export default BaseLayout

const RootWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: #f2f3f8;
`