import $config from "@/utils/Config";

const useSettings = () => {
    const settings = $config.settings;

    return {
        data: settings,
    };
};

export default useSettings;
