import React, { useEffect, useState } from 'react';
import { useGlobalStore } from '@/stores/globalStore';
import { useMapStore } from '@/stores/mapStore';
import { translate } from '@/i18n';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import useWindowSize from '@/hooks/useWindowSize';
import { nanoid } from 'nanoid';
import jsPDF from 'jspdf';
import Konva from 'konva';

import { saveAs } from 'file-saver'

const LayerPrinter = ({ onClick }) => {
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer);
  const stage = useMapStore((state) => state.stage);
  const size = useMapStore((state) => state.size);
  const initialScale = useMapStore((state) => state.initialScale);
  const isLoading = useMapStore((state) => state.isLoading);

  const { width, height } = useWindowSize();

  const [scale, setScale] = useState(1);
  const [print, setPrint] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const printCondition = print && loaded;

  const handleLoaded = () => {
    setLoaded(true);
  };

  const handlePrintLayer = (customDetails = {}) => {
    const layerId = customDetails['detail']['layerId'];
    setActiveLayer(layerId);
    const previousScale = stage.scale();

    if (activeLayer == layerId) {
      setPrint(true);
      setLoaded(true);
      return;
    } else {
      setLoaded(false);
    }

    setScale(previousScale);
    setPrint(true);
  };

  useEffect(() => {
    if (print && loaded && !isLoading) {
      handlePrint();
    }
  }, [print, loaded, isLoading]);

  const rescale = React.useCallback(() => {
    if (!size[0] || !size[1]) return;

    const scaleX = width / size[0];
    const scaleY = (height - 60) / size[1];
    const newScale = Math.min(scaleX, scaleY);

    const newPos = {
      x: -(size[0] * newScale - width) / 2,
      y: -(size[1] * newScale - height + 60) / 2
    };

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();
  }, [stage, size, width, height]);

  const handlePrint = () => {
    setTimeout(() => {
      rescale();
      const cover = (stage as Konva.Stage).findOne('#cover-layer');
      const data = (stage as Konva.Stage).toDataURL({
        pixelRatio: 9,
        quality: 1,
        width: (cover ? cover.width() : stage.width()),
        height: cover ? cover.height() : stage.height(),
        x: cover ? cover.x() : 0,
        y: cover ? cover.y() : 0
      });
      saveAs(data, `${nanoid()}.jpg`)

      // const w = cover ? cover.width() : stage.width();
      // const h = cover ? cover.height() : stage.height();

      // const doc = new jsPDF('landscape', 'px', [w, h]);

      // doc.addImage(data, 'JPEG', 0, 0, w, h);
      // doc.save(`${nanoid()}.pdf`);
      // doc.autoPrint()

      setPrint(false);
      setLoaded(false);
    }, 1500);
  };
  
  useEffect(() => {
    document.addEventListener('map-loaded', handleLoaded, true);
    document.addEventListener('print-layer', handlePrintLayer, true);

    return () => {
      document.removeEventListener('map-loaded', handleLoaded, true);
      document.removeEventListener('print-layer', handlePrintLayer, true);
    };
  }, []);

  return (
    <PrimaryButton
      $fullWidth
      type='button'
      onClick={() => {
        onClick();
        // console.log("--------------DEBUG------------------");
        // console.log(print, loaded, isLoading, size, stage);
        // console.log("--------------DEBUG------------------");
      }}
      disabled={printCondition}
    >
      {printCondition ? translate('printing-layer') : translate('print-layer')}
    </PrimaryButton>
  );
};

export default LayerPrinter;
