import React, { useEffect, useMemo } from "react";
import MapStage from "@/components/shared/map/stage/MapStage";
import MapCoverLayer from "./MapCoverLayer";
import PolygonsLayer from "../polygon/PolygonsLayer";
import PointsLayer from "../point/PointsLayer";
import { useLayerView } from "@/api/hooks/useLayerView";
import { useMetadata } from "@/api/hooks/useMetadata";
import { useBookingsForLayer } from "@/api/hooks/useBookingsForLayer";
import { useGlobalStore } from "@/stores/globalStore";
import { useProjectStore } from "@/stores/projectStore";
import { useMapStore } from "@/stores/mapStore";
import { formatLocalDateToAPI } from "@/utils/helpers/dates.helpers";
import { addMinutes } from "date-fns";
import Tooltip from "../tooltip/Tooltip";
import { useUserCardFields } from "@/api/hooks/useUserCardFields";
import { useTree } from "@/api/hooks/useTree";
import { useToast } from "../../toast/useToast";
import useSettings from "../../../../hooks/settings/use-settings";

const dispatchLoaded = () => {
    const event = new CustomEvent("map-loaded");
    document.dispatchEvent(event);
};

const MapContainer = () => {
    const { enqueueToast } = useToast();
    const activeLayer = useGlobalStore((state) => state.activeLayer);
    const selection = useGlobalStore((state) => state.selection);
    const nodes = useProjectStore((state) => state.nodes);
    const setMapLayer = useMapStore((state) => state.setMapLayer);
    const node = nodes.find((n) => n.id == activeLayer);
    const hasOwnView = node?.ownView;
    const setActiveLayer = useGlobalStore((state) => state.setActiveLayer);
    const layer = hasOwnView ? node.id : node?.parent || activeLayer;
    const { layerView, isSuccess: layerViewLoading } = useLayerView(
        Number(layer)
    );
    const { metadata } = useMetadata();
    const { data: settings } = useSettings();
    const { data: userFields } = useUserCardFields();
    const { data, isSuccess } = useBookingsForLayer(
        Number(layer),
        formatLocalDateToAPI(selection.startDate),
        formatLocalDateToAPI(addMinutes(selection.endDate, -30))
    );

    const userDepartmentFieldId = useMemo(
        () =>
            userFields?.fields.find((field) => field.label === "#department")
                ?.uid || null,
        [userFields]
    );
    const { layers } = useTree();

    // Редирект на следующий слой, если в слое нет изображения
    useEffect(() => {
        if (layerView && !layerView?.view && layers) {
            const layerId = layer;
            const nodes = layers?.nodes;
            const currentLayerIndex = nodes?.findIndex(
                (node) => node.id === layerId
            );
            const currentLayer = nodes[currentLayerIndex]
            const nextLayerIndex =
                currentLayerIndex === nodes.length - 1
                    ? 0
                    : currentLayerIndex + 1;
            const nextLayer = nodes[nextLayerIndex];

            setActiveLayer(nextLayer.id);
            enqueueToast(
                `Слой "${currentLayer.name}" не имеет изображения. Открыт слой "${nextLayer.name}"`,
                {
                    variant: "error",
                }
            );
        }
    }, [layerView]);

    useEffect(() => {
        setMapLayer(Number(layer));
    }, [layer, activeLayer]);

    useEffect(() => {
        if (isSuccess && layerViewLoading) {
            dispatchLoaded();
        }
    }, [isSuccess, layerViewLoading, activeLayer]);

    return (
        <MapStage>
            <MapCoverLayer view={layerView?.view} />
            <PolygonsLayer polygons={layerView?.polygons} />
            <PointsLayer
                userDepartmentFieldId={userDepartmentFieldId}
                colors={settings?.colors || {}}
                nodes={metadata?.rawNodes}
                points={layerView?.points}
                options={layerView?.options}
                bookings={data?.bookings}
            />
            <Tooltip />
        </MapStage>
    );
};

MapContainer.whyDidYouRender = true;

export default MapContainer;
