import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ size = 35 }) => {
  return (
    <Svg>
      <circle cx={size} cy={size} r={size}></circle>
    </Svg>
  );
};

export default Loader;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const animate = keyframes`
  0% {
    stroke-dashoffset: 220;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 440;
  }
`;

const Svg = styled.svg`
  position: relative;
  width: 80px;
  height: 80px;
  animation: ${rotate} 2s linear infinite;

  circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 5;
    stroke: #fff;
    stroke-linecap: round;
    transform: translate(5px, 5px);
    stroke-dasharray: 220;
    stroke-dashoffset: 220;
    animation: ${animate} 4s linear infinite;
  }
`;
