import { useProjectStore } from '@/stores/projectStore'
import React from 'react'
import DateSelector from './DataSelector'

const DateSelectorBar = () => {
    const fullMode = useProjectStore(state => state.fullMode)

    if (!fullMode) return null

    return <DateSelector />
}

export default DateSelectorBar