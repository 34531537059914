import { useGlobalStore } from "@/stores/globalStore";
import { useMapStore } from "@/stores/mapStore";
import { useEffect } from "react";
import useSettings from "@/hooks/settings/use-settings";

const MapController = () => {
    const activeLayer = useGlobalStore((state) => state.activeLayer);
    const mapLayer = useMapStore((state) => state.mapLayer);
    const setPolygon = useMapStore((state) => state.setPolygon);
    const updateDepartments = useMapStore((state) => state.updateDepartments);

    const { data } = useSettings();

    useEffect(() => {
        if (data && data.departments) {
            updateDepartments(data.departments);
        }
    }, [data?.departments]);

    useEffect(() => {
        const predicate = activeLayer != mapLayer && mapLayer && mapLayer > 0;
        const shouldAnimate = Boolean(predicate);

        if (!shouldAnimate) {
            setPolygon(null);
            return;
        }

        setPolygon(activeLayer);
    }, [activeLayer, mapLayer]);

    return null;
};

export default MapController;
