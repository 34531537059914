import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useParallelBooking } from '@/api/hooks/useParallelBooking';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useBooking } from '@/api/hooks/useBooking';
import { useBookingDialogStore } from '../../BookingModal';
import { convertToPeriods } from '@/api/bookings';
import { areIntervalsOverlapping, endOfWeek, startOfWeek, isAfter, eachWeekOfInterval, setWeek, getWeek, addWeeks } from 'date-fns';
import { useBookingStore } from '@/stores/bookingStore';
import { extractGaps, generateGap, isDateInRange, formatToISOTimezone } from '@/utils/helpers/dates.helpers';
import { bookingInterval } from '@/utils/constants/booking.constants';
import media from '@/ui/media';
import useResponsive from '@/hooks/useResponsive';
import { translate } from '@/i18n';

export type BookingSlot = {
    start: Date
    end: Date
}

type GridCalendarProps = {
    addItem: Function
}

const getActualGaps = (slots, weekStart, weekEnd) => {
    if (!slots) return []

    return slots
        .concat()
        .filter(v => v.start && v.end).map(slot => {
            const isSlotAfter = isAfter(slot.start, slot.end)
            return isSlotAfter ? {
                start: slot.end,
                end: slot.start
            } : slot
        })
        .filter(slot => areIntervalsOverlapping(
            { start: slot.start, end: slot.end },
            { start: weekStart, end: weekEnd }
        ))
}

const GridCalendar: React.FC<GridCalendarProps> = () => {
    const bookingId = useBookingDialogStore(state => state.bookingId)
    const week = useBookingStore(state => state.week)
    
    const weekStart = startOfWeek(week, { weekStartsOn: 1 })
    const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

    const { isMobile } = useResponsive()
    const { values, setFieldValue } = useFormikContext<any>()
    const { data: current } = useBooking(bookingId)
    const { data, isLoading } = useParallelBooking({ nodeId: values.seat.id, userId: values.user.id, weekStart, weekEnd })

    // grid state
    const [selection, setSelection] = useState<any>({
        current: [],
        own: [],
        foreign: [],
        parallel: []
    })
    const [drag, setDrag] = useState<boolean>(false)
    const [deselect, setDeselect] = useState<boolean>(false)
    const [start, setStart] = useState<number | null>(null)
    const [end, setEnd] = useState<number | null>(null)

    const startBook = values.start || weekStart || startOfWeek(new Date(), { weekStartsOn: 1 })
    const endBook = values.type === '3' ? addWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1) : values.end || weekEnd || endOfWeek(new Date(), { weekStartsOn: 1 })

    const slots = values.dates
    const type = values.type
    const seat = values.seat.id

    // current booking slots
    const bookSlots = current?.slots.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end), weekStart), []) || []

    useEffect(() => {
        let otherSlots
        let parallelSlots

        // if (!data) return

        if (!data || !data.slots) {
            otherSlots = []
            parallelSlots = []
        } else {
            otherSlots = data.slots
            parallelSlots = data.parallel
        }

        const predicate = slot => slot.user === Number(values.user.id)
        const weekStart = startOfWeek(week, { weekStartsOn: 1 })
        const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

        let filteredByDate = slots
        
        if (type != '1') {
            filteredByDate = slots
                .filter(slot => slot.start && slot.end).filter(slot => areIntervalsOverlapping(
                    { start: slot.start, end: slot.end },
                    { start: startBook, end: endBook }
                ))
        }

        const newSlots = {
            current: filteredByDate,
            own: getActualGaps(otherSlots.filter(predicate), weekStart, weekEnd),
            foreign: getActualGaps(otherSlots.filter(slot => !predicate(slot)), weekStart, weekEnd),
            parallel: getActualGaps(parallelSlots.filter(predicate), weekStart, weekEnd),
        }

        const currentGaps = newSlots.current.reduce((acc, slot) => (slot.start && slot.end) ? acc.concat(convertToPeriods(slot.start, slot.end, weekStart)) : acc, [])

        const gaps = {
            current: currentGaps.filter(v => v >= 0 && v <= 355),
            own: newSlots.own.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end, weekStart)), []).filter(v => !bookSlots.includes(v)).filter(v => v >= 0 && v <= 355),
            foreign: newSlots.foreign.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end, weekStart)), []).filter(v => v >= 0 && v <= 355),
            parallel: newSlots.parallel.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end, weekStart)), []).filter(v => v >= 0 && v <= 355),
        }

        setSelection(gaps)

    }, [slots, week, seat, isLoading, isMobile])


    const isFilled = (id) => {
        if (!start || !end) return false
        const arr = [start, end].sort((a, b) => a - b)
        return id >= arr[0] && id <= arr[1]
    }

    function dragStartHandler(e) {
        if (type === '3' || isMobile) return
        const id = e.target.dataset.id
        const { own, current, foreign, parallel } = selection

        const isSelected = current.includes(Number(id))

        if (isSelected) {
            setDeselect(true)
        }

        const isMyBooking = own.includes(Number(id))
        const isOtherBooking = foreign.includes(Number(id))
        const isParallelBooking = parallel.includes(Number(id))

        const isExist = isMyBooking || isOtherBooking || isParallelBooking

        if (id && !isExist) {
            setDrag(true)
            setStart(id)
            setEnd(id)
        }
    }

    function dragOverHandler(e) {
        if (type === '3' || isMobile) return
        if (!drag) return

        const id = e.target.dataset.id
        if (id) {
            setEnd(id)
        }
    }

    function dragLeaveHandler(e) {
        if (type === '3' || isMobile) return
        setDrag(false)

        if (start && end) {
            const arr = [start, end].sort((a, b) => a - b)

            setStart(null)
            setEnd(null)
            let revalidation

            if (deselect) {
                const gap = generateGap(arr)
                revalidation = selection.current.filter(cell => !gap.includes(cell))
            } else {
                const gap = generateGap(arr)
                const { current, foreign } = selection
                const filtered = gap.filter(item => !(current.includes(item) || foreign.includes(item)))
                revalidation = selection.current.concat(filtered)
            }

            

            revalidate(revalidation)
        }

        setDeselect(false)
    }

    const revalidate = (revalidate) => {
        let timeSlots
        // const activeSlots = slots.filter(slot => !isDateInRange({ start: slot.start, end: slot.end}, { start: weekStart, end: weekEnd }))

        if (type == '2' && startBook && endBook) {
            const valid = revalidate.filter(v => v >=0 && v <= 355)
            const newSlots = extractGaps(valid.sort((a, b) => a - b))
            
            const weeks = eachWeekOfInterval({ start: startBook, end: endBook }, { weekStartsOn: 1 })
            timeSlots = weeks.reduce((acc, currWeek) => {
                const weekSlots: any[] = []
                newSlots.forEach(slot => {
                    const startDate = new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0])
                    const endDate = new Date(weekStart.getTime() + bookingInterval * Number(slot[1]) + bookingInterval)
                    
                    weekSlots.push({
                        start: setWeek(startDate, getWeek(currWeek), { weekStartsOn: 1 }),
                        end: setWeek(endDate, getWeek(currWeek), { weekStartsOn: 1 }),
                    })
                })
                return acc.concat(weekSlots)
            }, [] as any)

        } else {
            const newSlots = extractGaps(revalidate.sort((a, b) => a - b))

            timeSlots = newSlots.map(slot => ({
                start: new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0]),
                end: new Date(weekStart.getTime() + bookingInterval * Number(slot[1]) + bookingInterval)
            }))
        }

        setFieldValue('dates', [...timeSlots])
    }

    return (
        <CalendarWrapper>
            <GridDays>
                <GridDay>{translate('monday')}</GridDay>
                <GridDay>{translate('tuesday')}</GridDay>
                <GridDay>{translate('wensday')}</GridDay>
                <GridDay>{translate('thursday')}</GridDay>
                <GridDay>{translate('friday')}</GridDay>
                <GridDay>{translate('saturday')}</GridDay>
                <GridDay>{translate('sunday')}</GridDay>
            </GridDays>
            <Calendar
                onMouseDown={e => dragStartHandler(e)}
                onMouseUp={e => dragLeaveHandler(e)}
                onMouseMove={e => dragOverHandler(e)}
            >
                {Array.from({ length: 48 * 7 }).map((item, idx) => {
                    const filled = isFilled(idx)
                    const isMyBooking = selection.own.map(item => item).includes(idx)
                    const isSelected = selection.current.includes(idx) || filled
                    const isOtherBooking = selection.foreign.map(item => item).includes(idx)
                    const isParallelBooking = selection.parallel.map(item => item).includes(idx)
                    const isGapFilled = isMyBooking || isSelected || isOtherBooking || isParallelBooking
                    const color = isSelected ? "#079DAC" : isOtherBooking ? "#FD4F26" : isParallelBooking ? "#c795f1" : "#F3BB5E"

                    return (
                        <GridItem
                            key={idx}
                            // data-time={idx * 30 * 60 * 1000 + week.getTime()}
                            data-type={true}
                            data-id={idx}
                            $color={deselect && filled ? 'rgba(0,0,0,0.25)' : color}
                            $filled={isGapFilled}
                            $hoverable={isMobile}
                        // $filled={isFilled(idx)}
                        />
                    )
                })}
            </Calendar>
        </CalendarWrapper>
    )
}

export default GridCalendar

const GridDays = styled.div`
    display: grid;
    row-gap: 6px;
    width: 42px;
    grid-template-rows: repeat(7, 1fr);
`

const CalendarWrapper = styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
`

const Calendar = styled.div`
    display: grid;
    row-gap: 6px;
    grid-template-columns: repeat(48, 1fr);
    z-index: 11;
`

const GridDay = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
    display: flex;
    align-items: center;
    text-transform: capitalize;
`

const GridItem = styled.div<{ $filled?: boolean, $color?: string, $hoverable?: boolean }>`
    max-width: 13px;
    height: 13px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s;

    ${media.lg`
        max-width: 20px;
        border-radius: 0px;
    `}
    
    &:hover {
        transform: scale(1.5);
    }

    ${({ $filled, $color }) => $filled && $color && css`
        background: ${$color};
    `}

    ${({ $hoverable }) => $hoverable && css`
        cursor: default;
        
        &:hover {
            transform: none;
        }
    `}
`