import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProjectContainer from './ProjectContainer';
import LoginContainer from './LoginContainer';
import NotFound from '@/components/NotFound';
import ProtectedRoutes from "@/components/ProtectedRoutes";
import LogoutPage from "@/pages/logout";
import IFrameAuth from "@/components/IFrameAuth";
// import '@/settings.json'

const AppContainer = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<IFrameAuth />}>
                    <Route path="/login" element={<LoginContainer />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route index element={<ProjectContainer />} />
                    </Route>
                    <Route path="/logout" element={<LogoutPage />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default AppContainer