import { Layer } from 'react-konva'
import { memo, useEffect, useMemo } from 'react'
import Polygon from './Polygon'
import { useMapStore } from '@/stores/mapStore'
import { ITreeFieldType } from '@/components/Elements/tree'

const dispatchPolygonsLoaded = () => {
  const event = new CustomEvent('polygons-loaded')
  document.dispatchEvent(event)
}

const filterHiddenPolygons = (polygon: {
  fields: ITreeFieldType[]
}): boolean => {
  return !polygon.fields.some(
    ({ name, value }) => name === '#hidden-from-map' && value === true,
  )
}

const PolygonsLayer: React.FC<any> = ({ polygons = [] }) => {
  const layers = useMapStore(state => state.layers)

  const isVisible = useMemo(() => layers['layers'], [layers])
  const [width, height] = useMapStore(state => state.size)

  useEffect(() => {
    dispatchPolygonsLoaded()
  }, [polygons])

  if (!isVisible) return null

  console.log(polygons.filter(filterHiddenPolygons))

  return (
    <Layer>
      {polygons.filter(filterHiddenPolygons).map(({ id, polygon, fields }) => (
        <Polygon
          id={id}
          key={id}
          width={width}
          height={height}
          polygon={polygon}
          fields={fields}
        />
      ))}
    </Layer>
  )
}

export default memo(PolygonsLayer)

// PolygonsLayer.whyDidYouRender = true;
