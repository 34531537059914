import { useGlobalStore } from "@/stores/globalStore";
import $config from "@/utils/Config";

export function useProject() {
    // const project = useGlobalStore((state) => state.project);

    // console.log('project', project, $config.workspaceId, $config.projectId)
    return {
        workspaceId: $config.workspaceId,
        projectId: $config.projectId
    };
}
