import { BASE_DOMAIN } from "@/api";
import { useGlobalStore } from "@/stores/globalStore";
import React, { useMemo } from "react";
import { animated, Spring } from "@react-spring/konva";
import { easePoly } from "d3-ease";
import { useMapStore } from "@/stores/mapStore";
import { useUserStore } from "@/stores/userStore";
import LoadPoint from "@/components/shared/map/point/LoadPoint";
import BasicPoint from "@/components/shared/map/point/BasicPoint";

export const defaultMapPointImage = {
  owner_id: "15",
  image_id: "2d95a0df350c4c2dae7ab2c0c03bf337",
  extension: "png",
};

export const getImageLink = (image) => {
  if (!image || !image.owner_id) return "";
  return `${BASE_DOMAIN}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}`;
};

export const isBookableForMe = (data, categories) => {
  if (!data) return false;
  const plugin: any = Object.values(data).find((obj: any) => obj?.bookable);
  if (!plugin) return false;
  const category = plugin.category;
  const bookable = plugin.bookable;
  const isAvailableForCategory = categories.includes(category);
  const isAvailableForBooking =
    isAvailableForCategory === true && bookable == true;

  return isAvailableForBooking;
};

const defaultNode = {
  background: "#ffffff",
  border: "#000000",
  radius: 50,
  uid: "ae4c38a5a5994d8082029b51370111a3",
  name: "Сервер",
};

const Point = (props) => {
  const layers = useMapStore((state) => state.layers);
  const option = useMapStore((state) => state.option);

  const categories = useUserStore((state) => state.categories);

  const { point, nodes, bookings, userDepartmentFieldId } = props;
  const { id, type_uid, plugin_data } = point;

  const allBookings = useMemo(
    () => bookings?.filter((book) => book.point_id == id),
    [bookings, id]
  );
  const bookingData = useMemo(
    () => bookings?.find((book) => book.point_id == id),
    [bookings, id]
  );

  const available = useMemo(
    () => isBookableForMe(plugin_data, categories),
    [plugin_data, categories]
  );
  const node = nodes[type_uid] || defaultNode;

  const department = useMemo(() => {
    // @ts-ignore
    const departmentPlugins = Object.values(node.plugin_data).find(
      (v) => v?.["fields"]
    )?.["fields"];
    const departmentField = departmentPlugins?.find(
      (plugin) => plugin.name === "#department"
    );

    const departmentFieldId = departmentField?.id;
    if (!departmentFieldId) return null;

    let department;

    Object.values(point.plugin_data).forEach((plugin: any) => {
      if (plugin["field_" + departmentFieldId]) {
        department = plugin["field_" + departmentFieldId];
      }
    });

    return department;
  }, [node, point]);

  const userDepartment = useMemo(
    () => (bookingData ? bookingData[userDepartmentFieldId] : null),
    [bookingData, userDepartmentFieldId]
  );
  const hasDepartment = userDepartment || department;
  const isVisible = useMemo(
    () =>
      hasDepartment
        ? layers["departments." + hasDepartment]
        : available
        ? layers["no-departments"]
        : true,
    [layers, hasDepartment, available]
  );

  if (option === "load")
    return (
      <LoadPoint
        {...props}
        allBookings={allBookings}
        available={available}
        node={node}
      />
    );

  if (!isVisible) return null;

  return (
    <BasicPoint
      {...props}
      hasDepartment={hasDepartment}
      bookingData={bookingData}
      allBookings={allBookings}
      available={available}
      node={node}
    />
  );
};

export const scaleRelativeToPoint = (stage, point, scale, onRescale) => {
  const scaleBy = 1.25;
  const oldScale = stage.scaleX();

  const mousePointTo = {
    x: point.x / oldScale - stage.x() / oldScale,
    y: point.y / oldScale - stage.y() / oldScale,
  };

  const newScale = scale;
  const x = (point.x / newScale - mousePointTo.x) * newScale;
  const y = (point.y / newScale - mousePointTo.y) * newScale;

  onRescale({
    scale: newScale,
    point: {
      x,
      y,
    },
  });
};

export const AnimatedPoint: React.FC<any> = React.memo((props) => {
  const { id } = props;

  const seat = useGlobalStore((state) => state.seat);
  const currentSeat = useMemo(() => seat == id, [seat, id]);

  return (
    <Spring
      from={{ opacity: 1 }}
      to={
        currentSeat
          ? [{ opacity: 0.2 }, { opacity: 1 }, { opacity: 0.2 }, { opacity: 1 }]
          : { opacity: 1 }
      }
      config={{
        easing: easePoly.exponent(2),
      }}
    >
      {(springProps) => (
        // @ts-ignore
        <animated.Group {...springProps}>
          <Point {...props} />
        </animated.Group>
      )}
    </Spring>
  );
});

AnimatedPoint.whyDidYouRender = true;

export default React.memo(Point);
