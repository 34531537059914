import React from 'react'
import styled from 'styled-components'
import create from "zustand"
import Modal from '@/components/Modal/Modal';
import Close from '@/components/ui/button/Close';
import Grid from '../Grid';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import MaintenanceForm from './MaintenanceForm';
import { translate } from '@/i18n';

type MaintenanceDialogStore = {
    isOpen: boolean
    employee?: string
    seat?: string
    onClose: () => void
}

type MaintenanceDialogProps = {
    seat: string
    employee: string
}


export const useMaintenanceDialogStore = create<MaintenanceDialogStore>((set) => ({
    isOpen: false,
    onClose: () => {
        set({
            employee: undefined,
            seat: undefined,
            isOpen: false
        })
    }
}))

export const maintenanceDialog = ({ seat, employee }: MaintenanceDialogProps) => {
    useMaintenanceDialogStore.setState({
        isOpen: true,
        seat,
        employee
    })
}


const MaintenanceModal = () => {
    const isOpen = useMaintenanceDialogStore(state => state.isOpen)
    const onClose = useMaintenanceDialogStore(state => state.onClose)

    return (
        <Modal maxWidth={480} isOpen={isOpen} onClose={onClose}>
            <Header>
                <Title>{translate('object-service')}</Title>
                <Close color="#000" onClick={onClose} />
            </Header>

            <Container>
                <MaintenanceForm />
            </Container>
        </Modal>
    )
}

export default MaintenanceModal

const Container = styled.div``

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
`